import { useDispatch } from "react-redux";
import { keys, values } from "lodash";
import cn from "classnames";
import { AlertOption } from "../../../../../../types/email-preferences/EmailPreferences";
import { BwicFilterType } from "../../../../../../types/filters/FilterState";
import { PortfolioUserConfigFilter } from "../../../../../../types/user-config/UserConfigFilter";
import { ListBuilder } from "../../../../../../utils/ListBuilder";
import { SavedFilterDetails } from "../../../../../amrPipeline/aggregated/email-alerts/issuance-monitor/SavedFilterDetails";
import { Table } from "../../../../../bidding/common/table"
import { PortfolioAlertsRowType, PortfolioSavedFilterAlertsState } from "../../../../../../types/state/ManageEmailPreferencesState";
import { useAppSelector } from "../../../../../../effects/useAppSelector";
import { IColumnDefinition } from "../../../../../bidding/common/table/types/ColumnDefinition";
import { ColumnBuilder } from "../../../../../bidding/common/table/columns/column-builder/ColumnBuilder";
import { Checkbox } from "../../../../../controls";
import { manageEmailPreferencesActions } from "../../../../../../actions/manage-email-preferences.actions";
import { AlertEnabledState } from "../../../../../../types/enums/AlertEnabledState";
import { useEffect } from "react";
import { apiUtils } from "../../../../../../utils";
import { OnHoverTooltip } from "../../../../../common/OnHoverTooltip";
import { ActionBlocker } from "../../../../../access/ActionBlocker";
import { SubscriptionFeature } from "../../../../../../types/billing/SubscriptionFeature";
import { user } from "../../../../../../user/user";
import { roles } from "../../../../../../constants/roles";
import { SubscribeLink } from "../../../../../access/BlockedFeatureText";
import { RequiredFeature } from "../../../../../access/RequiredFeature";


const convertEnabledStateToAlertOption = (value: AlertEnabledState) => {
    if(value === AlertEnabledState.PartiallyEnabled) return null;
    if(value === AlertEnabledState.Enabled) return AlertOption.Instant;
    return AlertOption.Never
}

const convertAlertOptionToEnabledState = (alertOption: AlertOption) => {
    return [AlertOption.Instant, AlertOption.Daily].includes(alertOption)
        ? AlertEnabledState.Enabled
        : AlertEnabledState.Disabled
};

const calculateSavedFiltersGlobalState = (totalFilters: number, selectedFilters: number) => {
    if (totalFilters && totalFilters === selectedFilters) return AlertOption.Instant
    if (!selectedFilters) return AlertOption.Never;
    return null;
}

interface IPortfolioAlertRow {
    title: string;
    rowType: PortfolioAlertsRowType;
    bwicAlert: AlertOption | null; // null if partial;
    inventoryAlert: AlertOption | null; // null if partial;
    filterReferenceName?: string
    disabled?: boolean;
}

interface Props {
    savedFilters: PortfolioUserConfigFilter[];
}

export const PortfolioAlertsTable = ({ savedFilters }: Props) => {
    const dispatch = useDispatch();

    const allSecuritiesBwicAlert = useAppSelector(s => s.manageEmailPreferences.editPreferences.portfolioBwicAlertState);
    const allSecuritiesInventortAlert = useAppSelector(s => s.manageEmailPreferences.editPreferences.portfolioDealersInventoryAlertState);
    const filter = useAppSelector(s => s.filters.portfolio.filter);
    const filtersAlertState = useAppSelector(s => s.manageEmailPreferences.portfolioSavedFilterAlerts);
    const visibleFilters = useAppSelector(s => s.filters.portfolio.visibleFilters);

    const isBd = user.hasRoles(...roles.bd());

    useEffect(() => {
        if (savedFilters.length && !keys(filtersAlertState).length) {
            const initialState: PortfolioSavedFilterAlertsState =
                apiUtils.normalize(
                    savedFilters,
                    f => f.referenceName,
                    f => ({
                        bwicAlert: (f as PortfolioUserConfigFilter).bwicAlertOption,
                        inventoryAlert: (f as PortfolioUserConfigFilter).dealerInventoryAlertOption
                    })
                );

            dispatch(manageEmailPreferencesActions.initPortfolioFilterAlerts(initialState));
        }
    }, [savedFilters, filtersAlertState, dispatch])

    const handleBwicAlertChange = (checked: Boolean, row: IPortfolioAlertRow) => {
        const alertOption = checked ? AlertOption.Instant : AlertOption.Never;

        switch (row.rowType) {
            case PortfolioAlertsRowType.SavedFiltersHeader:
                dispatch(manageEmailPreferencesActions.portfolioSavedFilterGlobalAlertChange(
                    alertOption, row.inventoryAlert!));
                break;
            case PortfolioAlertsRowType.SavedFilter:
                dispatch(manageEmailPreferencesActions.portfolioSavedFilterAlertChange(
                    row.filterReferenceName!, alertOption, row.inventoryAlert));
                break;
            default:
                dispatch(manageEmailPreferencesActions.setPortfolioBwicAlerts(
                    convertAlertOptionToEnabledState(alertOption!)));
        }
    }

    const handleInventoryAlertChange = (checked: Boolean, row: IPortfolioAlertRow) => {
        const alertOption = checked ? AlertOption.Instant : AlertOption.Never;

        switch (row.rowType) {
            case PortfolioAlertsRowType.SavedFiltersHeader:
                dispatch(manageEmailPreferencesActions.portfolioSavedFilterGlobalAlertChange(
                    row.bwicAlert!, alertOption));
                break;
            case PortfolioAlertsRowType.SavedFilter:
                dispatch(manageEmailPreferencesActions.portfolioSavedFilterAlertChange(
                    row.filterReferenceName!, row.bwicAlert, alertOption));
                break;
            default:
                dispatch(manageEmailPreferencesActions.setPortfolioInventoryAlerts(
                    convertAlertOptionToEnabledState(alertOption!)));
        }
    }

    const getColumns = () => {
        const columns: IColumnDefinition<IPortfolioAlertRow>[] = [
            {
                columnKey: "filterName",
                renderColumnHeaderContent: () => "Alerts",
                renderColumnContent: row => <OnHoverTooltip overlay={row.title}>{row.title}</OnHoverTooltip>,
                className: "data-list-cell-xxxl"
            }, {
                columnKey: "bwicAlert",
                renderColumnHeaderContent: () => "BWIC Alerts",
                renderColumnContent: row =>
                    <ActionBlocker
                        feature={SubscriptionFeature.PortfolioAlerts}
                        overrideRequiredFeatures={row.rowType === PortfolioAlertsRowType.AllSecurities}
                    >
                        <Checkbox
                            disabled={row.disabled}
                            partially={row.bwicAlert === null}
                            checked={row.bwicAlert === AlertOption.Instant}
                            onChange={e => handleBwicAlertChange(e.target.checked, row)}
                        />
                    </ActionBlocker>,
                className: "data-list-cell-lg"
            }, {
                columnKey: "dealerInventortAlert",
                renderColumnHeaderContent: () => isBd ? "Inventory Alerts" : "Dealer Inventory Alerts",
                renderColumnContent: row =>
                    <ActionBlocker
                        feature={SubscriptionFeature.PortfolioAlerts}
                        overrideRequiredFeatures={row.rowType === PortfolioAlertsRowType.AllSecurities}
                    >
                        <Checkbox
                            disabled={row.disabled}
                            partially={row.inventoryAlert === null}
                            checked={row.inventoryAlert === AlertOption.Instant}
                            onChange={e => handleInventoryAlertChange(e.target.checked, row)}
                        />
                    </ActionBlocker>,
                className: "data-list-cell-lg"
            }
        ];

        return columns.map(c => new ColumnBuilder(c));
    }

    const gitFilterRowTitle = (filter: PortfolioUserConfigFilter) => {
        const filterCount = keys(filter.filter).length;
        const filterWord = filterCount === 1 ? "filter" : "filters";
        return `${filter.name} (${filterCount} ${filterWord})`;
    }

    const getTableRows = () => {
        const allSecuritiesRow: IPortfolioAlertRow = {
            title: 'All Securities',
            rowType: PortfolioAlertsRowType.AllSecurities,
            bwicAlert: convertEnabledStateToAlertOption(allSecuritiesBwicAlert),
            inventoryAlert: convertEnabledStateToAlertOption(allSecuritiesInventortAlert),
        };

        const alertValues = values(filtersAlertState);
        const enabledBwicFiltersCount = alertValues.filter(x => x.bwicAlert === AlertOption.Instant).length;
        const enabledInventoryFiltersCount = alertValues.filter(x => x.inventoryAlert === AlertOption.Instant).length;

        const securitiesMatchingSavedFilters: IPortfolioAlertRow = {
            title: 'Securities matching saved filters',
            rowType: PortfolioAlertsRowType.SavedFiltersHeader,
            bwicAlert: calculateSavedFiltersGlobalState(savedFilters?.length ?? 0, enabledBwicFiltersCount),
            inventoryAlert: calculateSavedFiltersGlobalState(savedFilters?.length ?? 0, enabledInventoryFiltersCount),
            disabled: !savedFilters?.length
        };

        const savedFilterRows: IPortfolioAlertRow[] = savedFilters.map(f => ({
            title: gitFilterRowTitle(f),
            rowType: PortfolioAlertsRowType.SavedFilter,
            bwicAlert: filtersAlertState[f.referenceName]?.bwicAlert ?? AlertOption.Never,
            inventoryAlert: filtersAlertState[f.referenceName]?.inventoryAlert ?? AlertOption.Never,
            filterReferenceName: f.referenceName
        }));

        return new ListBuilder()
            .add(allSecuritiesRow)
            .add(securitiesMatchingSavedFilters, ...savedFilterRows)
            .result();
    }

    const renderFilterDetails = (row: IPortfolioAlertRow) => {
        if (row.rowType !== PortfolioAlertsRowType.SavedFilter) return null;

        const savedFilter = savedFilters?.find(sf => sf.referenceName === row.filterReferenceName);

        if (!savedFilter) return null;

        return (
            <SavedFilterDetails
                userFilter={savedFilter!}
                filter={filter}
                visibleFilters={visibleFilters}
                filterType={BwicFilterType.Portfolio}
            />
        );
    }

    return (
        <>
            <Table
                dataItems={getTableRows()}
                columns={getColumns()}
                className={cn("data-list-striped data-list-saved-filter", { "margin-b-0": !savedFilters.length })}
                shouldRenderCollapsibleRowCallback={(row: IPortfolioAlertRow) => row.rowType === PortfolioAlertsRowType.SavedFilter}
                renderCollapsibleItem={renderFilterDetails}
                createSecurityCustomClassName={(row: IPortfolioAlertRow) =>
                    row.rowType !== PortfolioAlertsRowType.SavedFilter && "row-grouping"
                }
            />
            {!savedFilters.length ? <div className="placeholder-row flex-row text-sm">
                <RequiredFeature
                    inline
                    feature={SubscriptionFeature.PortfolioSavedFilters}
                    text={<><SubscribeLink /> to unlock the saved filters.</>}
                >
                    <span className="text-warm-grey">There are no saved filters yet to receive email notifications.</span>
                </RequiredFeature>
            </div> : null}
        </>
    )
}