import { Link } from "react-router-dom";
import { routes } from '../../../../../../constants/routes';
import { isRequestNone, isRequestSuccess } from "../../../../../../utils";
import { Preloader } from "../../../../../common/Preloader";
import { Popup, PopupBody, PopupFooter } from "../../../../../controls"
import { EmailPreferencesTab } from "../../EmailPreferencesContent";
import { PortfolioAlertsTable } from "./PortfolioAlertsTable";
import { SavePortfolioAlertsButton } from "./SavePortfolioAlertsButton";
import { usePageConfig } from "../../../../../common/pageConfig/usePageConfig";
import { PageConfigType } from "../../../../../../types/page-config/PageConfigType";
import { BwicFilterType } from "../../../../../../types/filters/FilterState";
import { PortfolioUserConfigFilter } from "../../../../../../types/user-config/UserConfigFilter";
import { useEffect } from "react";
import { useAppSelector } from "../../../../../../effects/useAppSelector";
import { useDispatch } from "react-redux";
import { manageEmailPreferencesActions } from "../../../../../../actions/manage-email-preferences.actions";

interface Props {
    onClose: () => void;
}

export const PortfolioAlertsPopup = ({ onClose }: Props) => {
    const dispatch = useDispatch();

    const { config, requestState } = usePageConfig(PageConfigType.Portfolio, true, BwicFilterType.Portfolio);
    const requestStateGetPreferences = useAppSelector(s => s.manageEmailPreferences.requestStateGetPreferences);
    const savePreferencesRequestState = useAppSelector(s => s.manageEmailPreferences.requestStateSaveEditPreferences);

    useEffect(() => {
        if (isRequestSuccess(savePreferencesRequestState)) {
            onClose();
        } // eslint-disable-next-line
    }, [savePreferencesRequestState])

    useEffect(() => () => {
        dispatch(manageEmailPreferencesActions.reset())
    }, [dispatch])

    useEffect(() => {
        if (isRequestNone(requestStateGetPreferences)) {
            dispatch(manageEmailPreferencesActions.getInitialDataRequest());
        }
    }, [dispatch, requestStateGetPreferences]);

    return (
        <Popup
            renderInBody
            title="Portfolio Alerts"
            modalClass="modal-email-preferences email-preferences-fixed-height"
            onClose={onClose}
        >
            <PopupBody>
                <Preloader inProgress={!isRequestSuccess(requestState, requestStateGetPreferences)}>
                    <div className="page-content-profile">
                        <div className="profile-content">
                            <div className="notifications-center">
                                <div className="controls-wrap">
                                    <p className="controls-wrap-info">
                                        Receive email notifications when security from your portfolio is on a BWIC or has been added,
                                        updated, or traded in the Dealer Inventory List.
                                    </p>
                                    <PortfolioAlertsTable
                                        savedFilters={config?.filters as PortfolioUserConfigFilter[] ?? []} />
                                </div>
                            </div>
                        </div>
                    </div>
                </Preloader>
            </PopupBody>
            <PopupFooter>
                <Link to={routes.profileNotificationsTab(EmailPreferencesTab.Portfolio)} className="btn-link margin-r-a">
                    Manage Alerts
                </Link>
                <button className="btn btn-ghost" onClick={onClose}>
                    Cancel
                </button>
                <SavePortfolioAlertsButton />
            </PopupFooter>
        </Popup>
    )
}
