import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { manageEmailPreferencesActions } from '../../../../../../actions/manage-email-preferences.actions';
import { routes } from '../../../../../../constants/routes';
import { useAppSelector } from '../../../../../../effects/useAppSelector';
import { AlertEnabledState } from '../../../../../../types/enums/AlertEnabledState';
import { StatusMessageSectionType } from '../../../../../../types/state/NotificationState';
import { isRequesting } from '../../../../../../utils';
import { RouteLeavingGuard } from '../../../../../routing';
import { StatusMessageSection } from '../../../../../status-message/StatusMessageSection';
import { PortfolioAlertsTable } from './PortfolioAlertsTable';
import { SavePortfolioAlertsButton } from './SavePortfolioAlertsButton';
import { PageConfigType } from '../../../../../../types/page-config/PageConfigType';
import { BwicFilterType } from '../../../../../../types/filters/FilterState';
import { usePageConfig } from '../../../../../common/pageConfig/usePageConfig';
import { PortfolioUserConfigFilter } from '../../../../../../types/user-config/UserConfigFilter';
import { usePortfolioAlertsChangedState } from './usePortfolioAlertsChangedState';
import { UnsubscribePortfolioAlertsButton } from './UnsubscribePortfolioAlertsButton';

export function PortfolioTab() {
    const dispatch = useDispatch();
    const history = useHistory();
    const { config, requestState, updateRequestState } = usePageConfig(PageConfigType.Portfolio, true, BwicFilterType.Portfolio);

    const initialPortfolioBwicAlertState = useAppSelector(s => s.manageEmailPreferences.initialPreferences.portfolioBwicAlertState);
    const initialPortfolioInvnetoryAlertState = useAppSelector(s => s.manageEmailPreferences.initialPreferences.portfolioDealersInventoryAlertState);
    const savePreferencesRequestState = useAppSelector(s => s.manageEmailPreferences.requestStateSaveEditPreferences);

    const hasChanges = usePortfolioAlertsChangedState();

    return (
        <>
            <div className="flex-row save-changes">
                {
                    (initialPortfolioBwicAlertState === AlertEnabledState.PartiallyEnabled || initialPortfolioInvnetoryAlertState === AlertEnabledState.PartiallyEnabled) &&
                    <StatusMessageSection type={StatusMessageSectionType.Info}>
                        Some alerts are disabled on partial check, see <Link to={routes.portfolio}>Portfolio</Link> page for
                        details and management.
                    </StatusMessageSection>
                }
                <div className="flex-item-right">
                    <UnsubscribePortfolioAlertsButton 
                        disabled={isRequesting(requestState, updateRequestState, savePreferencesRequestState)}
                    />
                    <SavePortfolioAlertsButton className="btn-sm" />
                </div>
            </div>
            <div className="controls-wrap">
                <p className="controls-wrap-info">Receive email notifications when security from your portfolio is on a BWIC or has been added, updated, or traded in the Dealer Inventory List.</p>
                <PortfolioAlertsTable savedFilters={config?.filters as PortfolioUserConfigFilter[] ?? []} />
                <RouteLeavingGuard
                    navigate={(pathname: string) => {
                        dispatch(manageEmailPreferencesActions.rollbackPortfolio())
                        history.push(pathname);
                    }}
                    shouldBlockNavigation={() => hasChanges}
                />
            </div>
        </>
    )
}
