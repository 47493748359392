import { useDispatch } from "react-redux";
import cn from "classnames";
import { manageEmailPreferencesActions } from "../../../../../../actions/manage-email-preferences.actions";
import { useAppSelector } from "../../../../../../effects/useAppSelector";
import { BwicFilterType } from "../../../../../../types/filters/FilterState";
import { PageConfigType } from "../../../../../../types/page-config/PageConfigType";
import { usePageConfig } from "../../../../../common/pageConfig/usePageConfig";
import { isRequestNone, isRequesting } from "../../../../../../utils/request-state.utils";
import { PortfolioUserConfigFilter } from "../../../../../../types/user-config/UserConfigFilter";
import { usePortfolioAlertsChangedState } from "./usePortfolioAlertsChangedState";
import { AlertOption } from "../../../../../../types/email-preferences/EmailPreferences";

interface Props {
    className?: string;
}

export function SavePortfolioAlertsButton({ className }: Props) {
    const dispatch = useDispatch();

    const { config, saveAlerts, requestState, updateRequestState } =
        usePageConfig(PageConfigType.Portfolio, true, BwicFilterType.Portfolio);
    const hasChanges = usePortfolioAlertsChangedState();
    const filtersAlertState = useAppSelector(s => s.manageEmailPreferences.portfolioSavedFilterAlerts);
    const savePreferencesRequestState = useAppSelector(s => s.manageEmailPreferences.requestStateSaveEditPreferences);
    const filters = config?.filters as PortfolioUserConfigFilter[] ?? [];

    const handleSave = () => {
        if (filters.length) {
            const newFilterAlerts = filters.map(f => ({
                ...f,
                bwicAlertOption: filtersAlertState[f.referenceName]?.bwicAlert ?? AlertOption.Never,
                dealerInventoryAlertOption: filtersAlertState[f.referenceName]?.inventoryAlert ?? AlertOption.Never,
            }));

            saveAlerts(newFilterAlerts);
        }

        dispatch(manageEmailPreferencesActions.saveEmailPreferencesRequest());
    }

    return (
        <button
            className={cn("btn btn-main", className)}
            disabled={
                !hasChanges ||
                isRequestNone(requestState) ||
                isRequesting(updateRequestState, savePreferencesRequestState, requestState)
            }
            onClick={handleSave}>
            Save
        </button>
    )
}