import { useState } from "react";
import { AlertOption } from "../../../../../../types/email-preferences/EmailPreferences";
import { Portfolio } from "../../../../../../types/portfolio/Portfolio";
import { Popup, PopupBody, PopupFooter } from "../../../../../controls";
import { EmailPreferencesRadioGroup } from "../EmailPreferencesRadioGroup";
import { portfolioActions } from "../../../../../../actions/portfolio.actions";
import { useAppDispatch } from "../../../../../../effects/useAppDispatch";
import { textLabels } from "../../../../../../constants/text-labels";

interface Props {
    portfolio: Portfolio;
    onClose: () => void;
}

export function SinglePortfolioAlertsPopup({ portfolio, onClose }: Props) {
    const dispatch = useAppDispatch();

    const [bwicAlert, setBwicAlert] = useState(portfolio.bwicAlert);
    const [inventoryAlert, setInventoryAlert] = useState(portfolio.dealersInventoryAlert);

    const hasChanges = bwicAlert !== portfolio.bwicAlert || inventoryAlert !== portfolio.dealersInventoryAlert;

    const handleSave = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation()
        if (!hasChanges) return;

        dispatch(portfolioActions.updatePortfoliosAlertStatus([{
            portfolioId: portfolio.id,
            bwicAlert: bwicAlert === portfolio.bwicAlert ? undefined : bwicAlert,
            dealersInventoryAlert: inventoryAlert === portfolio.dealersInventoryAlert ? undefined : inventoryAlert
        }]));

        onClose();
    }

    const handleClose = (e?: React.MouseEvent<HTMLButtonElement> | MouseEvent) => {
        e?.stopPropagation()
        onClose();
    }

    return (
        <Popup
            modalClass="modal-email-preferences"
            renderInBody
            title={<>Portfolio Alerts <span className="name-tag">{portfolio.name}</span></>}
            onClose={handleClose}
        >
            <PopupBody className="notifications-center">
                <EmailPreferencesRadioGroup
                    value={bwicAlert ? AlertOption.Instant : AlertOption.Never}
                    alertOptions={[AlertOption.Never, AlertOption.Instant]}
                    alertOptionInfo={{
                        [AlertOption.Instant]: textLabels.savedFiltersPortfolioBwicInstantAlert
                    }}
                    groupNameIndentifier="single-portfolio-bwic-alert-state"
                    title="BWIC Alerts"
                    description={textLabels.savedFiltersPortfolioBwicAlertDescription}
                    onChange={o => setBwicAlert(o === AlertOption.Instant)}
                />
                <EmailPreferencesRadioGroup
                    value={inventoryAlert ? AlertOption.Instant : AlertOption.Never}
                    alertOptions={[AlertOption.Never, AlertOption.Instant]}
                    alertOptionInfo={{
                        [AlertOption.Instant]: textLabels.saveFiltersPortfolioInventoryInstantsAlert
                    }}
                    groupNameIndentifier="single-portfolio-inventory-alert-state"
                    title="Inventory Alerts"
                    description={textLabels.savedFiltersPortfolioInventoryAlertDescription}
                    onChange={o => setInventoryAlert(o === AlertOption.Instant)}
                />
            </PopupBody>
            <PopupFooter>
                <button className="btn btn-ghost" onClick={handleClose}>
                    Cancel
                </button>
                <button
                    className="btn btn-main"
                    onClick={handleSave}
                    disabled={!hasChanges}
                >
                    Save
                </button>
            </PopupFooter>
        </Popup>
    );
}